.tViewRowHeader {
    width: 100%;
    padding: 1rem;
    /* border: 1px solid #ddd; */
    border-radius: 10px;
    background-color: #fff;
    font-size: 1.7rem;
    font-weight: 500;
}

.tViewRow {
    width: 100%;
    /* border: 1px solid #ddd; */
    padding: 1rem;
    background-color: #fff;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px #ddd;
}

.tViewRowCell {
    /* border-bottom: 1px solid #ddd; */
    font-size: 1.3rem;
    padding: 5px;
}