Input::-webkit-outer-spin-button,
Input::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
     margin: 0 !important;
}

Input[type=number] {
    -moz-appearance: textfield !important;
}
.ant-spin-dot-item {
    background-color: #000;
}