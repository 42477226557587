@import "~antd/dist/antd.css"; 

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 1px;
}

body {
  background-color: #f0f2f7;
  overflow: hidden;
}
html {
  font-size: 62.5%;
}

/* .ant-message {
  width: 50%;
} */

.ant-spin-dot-item {
  background-color: #fff;
}

.scrolling::-webkit-scrollbar {
  display: none;
}