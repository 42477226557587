.loginWrapper {
    width: 50%;
    height: 100vh;
    background-color: #f5f6fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginBox {
    width: 30rem;
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    justify-content: center;
}

.loginBoxHeader {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center; 
}

.loginForm {
    width: 100%;
    display: flex;
    justify-content: center; 
    margin-top: 2rem;
}

.second {
    width: 50%;
    height: 100vh;
    background: #008acd;
    display: flex;
}