.inputContainer{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.inputMacElement{
    border-radius: 10px !important;
}

.divColons{
    font-size: 2rem;
    font-weight: 600;
    padding: 0 2px;
}