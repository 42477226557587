.sideNav {    
    /* display: flex;
    flex-direction: column; */
   /* height: 69.5rem;*/
    height: 100vh;
    width: 17.5rem;
    list-style-type: none;
    /* padding: 0 5px; */
    /* padding-left: 5px; */
    margin-bottom: 0 !important;
    background-color:#008acd;   
}

.sideNavItem {
    width: 100%;
    font-size: 1.2rem;
    letter-spacing: 1px;
    /* padding: 9px;
    padding-left: 1.5rem; */
    color: #fff;
}

.sideNavBtn {
    width: 100%;
    height: 100%;
    background-color: transparent;
    letter-spacing: 1px;
    border: 0;
    outline: none;
    text-align: left;
    padding: 9px;
    padding-left: 1.5rem;
}

.sideNavBtn:hover {
    cursor: pointer;
}


.sideNavItemDisabled {
    width: 100%;
    font-size: 1.2rem;
    padding: 9px;
    padding-left: 1.5rem;
    color: #a5b3c4;
}

.sideNavItemActive {
    /* background-color: #3e6db5; */
    background-color: #f0f2f7;
    color: #008acd;
    border-right: 4px solid #f0f2f7;
    /* border-radius: 5px; */
    /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 10px; */

    /* border-right: 4px solid #659FF9; */
}

.sideNavItem:hover {
    /* background-color: #3e6db5; */
    cursor: pointer;
}


.divider {
    width: 80%;
    min-width: 80%;
    margin: 3px 0;
    border-top: 1px solid #d3eaf5;
    /* border-top: 1px solid #7299c0; */
}

.leftArrow {
    font-size: 4rem;
    color: #fff !important;
}

.desktopIcon {
    padding: .3rem .7rem;
    color: #777;
    font-size: 1.5rem;
}

.desktopIcon:hover {
    background-color: #ddd;
}

.desktopExitIcon:hover {
    background-color: #ea4335;
}