@import "~antd/dist/antd.css"; 

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #008acd; 
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.082);
    z-index: 3;
}

.headerTitle {
    display: flex;
    align-items: center;
    letter-spacing: 2px;
}

.headerLogo {
    margin-right: 1rem;
}

.userName {
    display: flex;
    align-items: center;
}

.logoutBtn {
    display: flex;
    align-items: center;
    color: #2d3e83;
    border-radius: 7px;
}