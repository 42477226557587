.genBtn{
    background-color: #008acd;
    color: #fff;
    border: none;
    border-radius: 5px;
    /*display: none !important;*/
    /* margin: 2px 2px !important; */
}

.genBtn:hover{
    cursor: pointer;
    background-color: #106c9a;
}

.genBtnText{
    padding: 2px 4px;
}

.displayNone{
    display: none !important;
}
.trialModalNextSteps{
    padding-bottom: 1rem;
    font-size: 15px;
}
.trialModalUL{
    padding: 0 1rem;
}